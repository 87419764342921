import React from 'react';
import { Link } from 'react-router-dom';

import Layout from '../Layout/Layout';
import PageTitle from '../PageTitle/PageTitle';

import './SupportPage.css';

const SupportPage = ({ children, pageTitle }) => (
  <Layout pageTitle={pageTitle}>
    <PageTitle pageTitle={pageTitle} />
    <div className="container">
      <div className="row">
        <div className="twelve columns">
          {children}
          <p>You can <Link to="/contact-us">contact us</Link> if you need any more help.</p>
          <p><Link to="/support">Back to Support</Link></p>
        </div>
      </div>
    </div>
  </Layout>
);

export default SupportPage;
