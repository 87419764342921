import React from 'react';

import Layout from '../Layout/Layout';
import PageTitle from '../PageTitle/PageTitle';

import './ContactUs.css';

const ContactUs = () => {
  const pageTitle = 'Contact us';

  return (
    <Layout pageTitle={pageTitle}>
      <PageTitle pageTitle={pageTitle} />
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <p>We're always happy to hear from you whether it's a support request, a general enquiry or feedback about our service.</p>
            <h2>Support</h2>
            <p>Support requests must be sent from the email address registered to your account.</p>
            <ul className="contact__list">
              <li className="contact__item contact__item--email"><a href="mailto:support@x-designs.co.uk">Email us with a support request</a></li>
            </ul>
            <h2>General</h2>
            <ul className="contact__list">
              <li className="contact__item contact__item--email"><a href="mailto:info@x-designs.co.uk">Email us with a general enquiry</a></li>
              <li className="contact__item contact__item--twitter"><a href="https://www.twitter.com/xdesigns" target="_blank" rel="noopener noreferrer">Join us on Twitter</a></li>
              <li className="contact__item contact__item--skype"><a href="callto:x-designsltd">Contact us on Skype</a></li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
