import React from 'react';
import { Link } from 'react-router-dom';

import SupportPage from '../SupportPage/SupportPage';

const HowToChangeYourPassword = () => (
  <SupportPage pageTitle="How to change your password">
    <ol>
      <li>Log into webmail. If you're not sure how to log into webmail then read our guide about <Link to="/support/logging-into-webmail">logging into webmail</Link> first.</li>
      <li>Click Settings in the top toolbar</li>
      <li>Click Change Password in the left menu</li>
      <li>Enter the following:
        <ul>
          <li>Current password: Your current email password</li>
          <li>New password: Your new email password</li>
          <li>Confirm password: Your new email password again</li>
        </ul>
      </li>
      <li>Click Save Settings near the top right corner</li>
    </ol>
  </SupportPage>
);

export default HowToChangeYourPassword;
