import React from 'react';

import Layout from '../Layout/Layout';
import FeatureList from '../FeatureList/FeatureList';
import PageTitle from '../PageTitle/PageTitle';

const Features = () => {
  const pageTitle = 'Features';

  return (
    <Layout pageTitle={pageTitle} hasSignUp="true">
      <PageTitle pageTitle={pageTitle} />
      <div className="container">
        <div className="row">
          <div className="six columns">
            <FeatureList
              list={[
                'SSL enabled, including free SSL certificate',
                'FTP and SFTP file access',
                'Multiple FTP and SFTP users',
                'Password protectable folders',
                '10 email mailboxes',
                '100 email aliases, forwarders or groups',
                'Secure webmail access',
                'Secure desktop email access',
                'Secure mobile email access',
                'SPAM email filtering',
                'Email whitelisting and blacklisting',
                'Contact management',
                'Calendars',
              ]}
              listClass="features__list--first"
            />
          </div>
          <div className="six columns">
            <FeatureList
              list={[
                '1 MySQL database',
                'Secure phpMyAdmin database access',
                'Multiple database users',
                '5GB SSD storage split between website and email',
                'PHP5 and PHP7',
                'Cron Jobs',
                'Choose from over 20 managed web applications',
                'Clustered architecture',
                'DDoS and intrusion protection',
                'Continuous 24/7/365 operation',
                '30 day file backup and restore',
                'Automatic core updates for WordPress websites',
                'DNS management',
              ]}
              listClass=""
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Features;
