import React from 'react';

import SupportPage from '../SupportPage/SupportPage';

const SettingUpEmailInAppleMail = () => (
  <SupportPage pageTitle="Setting up email in Apple Mail">
    <ol>
      <li>Click the Apple logo</li>
      <li>Click System Preferences</li>
      <li>Click Internet Accounts</li>
      <li>Click Add Other Account</li>
      <li>Click Mail account</li>
      <li>Enter the following:
        <ul>
          <li>Name: This will be the name that appears in the From field of your email</li>
          <li>Email Address: Your email address</li>
          <li>Password: Your email password</li>
        </ul>
      </li>
      <li>Click Sign In</li>
      <li>Enter the following:
        <ul>
          <li>Email Address: Your email address</li>
          <li>Username: Your email address</li>
          <li>Password: Your email password</li>
          <li>Account Type: IMAP</li>
          <li>Incoming Mail Server: pdht-f2sx.accessdomain.com</li>
          <li>Outgoing Mail Server: pdht-f2sx.accessdomain.com</li>
        </ul>
      </li>
      <li>Click Sign In</li>
      <li>Click Done</li>
    </ol>
  </SupportPage>
);

export default SettingUpEmailInAppleMail;
