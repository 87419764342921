import React from 'react';
import { Link } from 'react-router-dom';

import Layout from '../Layout/Layout';
import PageTitle from '../PageTitle/PageTitle';

const PrivacyPolicy = () => {
  const pageTitle = 'Privacy policy';

  return (
    <Layout pageTitle={pageTitle}>
      <PageTitle pageTitle={pageTitle} />
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <p>
              At X-Designs we respect your privacy and are committed to maintaining the
              privacy and confidentiality of the personal information we collect.
            </p>
            <p>
              This privacy policy sets out how we use and protect any information that you give
              us when you use this website and our services.
            </p>
            <h2>What we collect</h2>
            <p>
              When you sign up for our services, we collect the following information: domain
              name, first name, last name, email address, your preference for a pre-installed app
              and your preference for receiving updates by email.
            </p>
            <h2>What we do with the information we collect</h2>
            <p>
              We use the information that we collect for the sole purpose of providing you with
              website hosting services. We may provide some or all of this information to our
              partner companies to enable us to provide our services to you.
            </p>
            <p>
              If you have indicated that you would like to receive updates by email we will
              occasionally send you emails about updates to our service, new products or special
              offers using the email address which you have provided. You can unsubscribe from
              these emails at any time by using the unsubscribe link found at the bottom of each
              email or you can <Link to="/contact-us">contact us</Link>.
            </p>
            <h2>How we protect your information</h2>
            <p>
              X-Designs uses a secure connection, known as SSL and denoted by https in your
              browser address bar. This encrypts the information that is sent between your
              browser and our servers.
            </p>
            <p>
              All the website and database backups that we create are encrypted and stored in
              secure locations.
            </p>
            <p>
              We use a secure payment provider to process and store your credit card information.
              None of this information is processed by our website or collected by us.
            </p>
            <h2>How we look after your information</h2>
            <p>
              We will not disclose, sell, distribute or lease your personal information to third
              parties unless specified in this privacy policy, we have your permission or are
              required to do so by law. Unless prohibited by law, we can give you details of any
              such third party disclosures upon request.
            </p>
            <p>
              Your personal information may be accessed, transferred to, and/or stored in a country
              outside the European Union in which data protection laws may be of a lower standard
              than within the European Union. Regardless of location, we will impose the same
              data protection safeguards that we use inside the European Union. If you would like
              more information about how your personal information may be transferred, please {" "}
              <Link to="/contact-us">contact us</Link>.
            </p>
            <p>
              We retain your personal information for the length of time your account is open. When
              you close your account we will immediately delete all your personal
              information from our systems and those of our partner companies.
            </p>
            <h2>How we keep your information up to date</h2>
            <p>
              You may request details of the personal information which we hold about you. If you
              would like a copy of the information we hold on you please {" "}
              <Link to="/contact-us">contact us</Link>.
            </p>
            <p>
              If you believe that any of the information we hold about you is incorrect or
              incomplete, please <Link to="/contact-us">contact us</Link> and we will
              promptly correct this information.
            </p>
            <h2>Changes to this privacy policy</h2>
            <p>
              We may change this privacy policy from time to time by updating this page. You
              should check this page regularly to ensure that you are happy with any changes.
            </p>
            <p>
              If you have any questions about this privacy policy then please {" "}
              <Link to="/contact-us">contact us</Link>.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
