import React from 'react';

import './SystemStatusItem.css'

const SystemStatusItem = ({ title, text }) => (
  <div className="system-status">
    <h3>{title}</h3>
    <p className="system-status__icon system-status__icon--good">
      <span className="visually-hidden">{text}</span>
    </p>
  </div>
);

export default SystemStatusItem;
