import React from 'react';
import { Link } from 'react-router-dom';

import SupportPage from '../SupportPage/SupportPage';

const WhatIsTheDifferenceBetweenEmailMailboxesAndEmailForwarders = () => (
  <SupportPage pageTitle="What is the difference between email mailboxes and email forwarders">
    <h2>Email mailboxes</h2>
    <p>
      Email mailboxes require you to log into the account using webmail or by setting up the email
      account on your desktop, laptop or mobile device to view the emails. You will be given a
      password and you will have full access to the account including being able to send emails
      from the email address of the account.
    </p>
    <h2>Email forwarders</h2>
    <p>
      Email forwarders will automatically forward emails sent to the forwarding account to another
      account of your choice, even accounts on a different domain. You will not be given a password
      and you will not have full access to the forwarding account so you will need to log into the
      receiving account to view the emails. You will not be able to send emails from the email
      address of the forwarding account.
    </p>
    <p>
      You can find out how many email mailboxes and email forwarders are included with your account
      on the <Link to="/features">features</Link> page.
    </p>
  </SupportPage>
);

export default WhatIsTheDifferenceBetweenEmailMailboxesAndEmailForwarders;
