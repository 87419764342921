import React from 'react';
import { Link } from 'react-router-dom';

import Layout from '../Layout/Layout';
import PageTitle from '../PageTitle/PageTitle';

const AboutUs = () => {
  const pageTitle = 'About us';

  return (
    <Layout pageTitle={pageTitle} hasSignUp="true">
      <PageTitle pageTitle={pageTitle} />
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <h2>Who are we?</h2>
            <p>
              At X-Designs we have provided website hosting services since we began in 2005. During that
              time we have worked with some fantastic customers, many of who we still work with today,
              and we strive to be very friendly and approachable and provide a transparent, reliable
              website hosting service that you can depend on.
            </p>
            <h2>Why host your website with us?</h2>
            <p>
              We provide a personal, managed approach to hosting your website. This means that we'll handle
              the day to day running of your website hosting such as setting up email accounts, taking and
              restoring backups and installing SSL certificates. This leaves you to concentrate on running
              your business.
            </p>
            <p>
              We like to keep things simple so we're not for complicated price plans, which is why we only
              have one price plan. This means that you’ll know you’re always getting our best price and when
              we add new features you’ll always get them instantly, such as when we increased the hosting
              space and when we added free SSL certificates.
            </p>
            <p>
              We believe that you should stay with us because of the service we provide not because we've
              locked you into a lengthy contract so you'll pay monthly and you can leave at any time. We're
              here for you so if you need help to move your website over then let us know, we'll do it for
              free and if you need any other help then let us know.
            </p>
            <p>
              We think the best way to keep providing a great service is for us to have complete confidence
              in our own services. One of the ways we do that is to use our products ourselves and so we use
              the same hosting that we provide to you for this website and other internal systems.
            </p>
            <h2>Have any questions?</h2>
            <p>
              We're always here so if you have any questions before or after signing up then please {" "}
              <Link to="/contact-us">contact us</Link>.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
};

export default AboutUs;
