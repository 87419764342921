import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';

import Layout from '../Layout/Layout';
import TextField from '../TextField/TextField';
import PageTitle from '../PageTitle/PageTitle';
import config from '../../config';

import './AddHosting.css';

const AddHosting = () => {
  const pageTitle = 'Add hosting';
  const stripe = useStripe();
  const elements = useElements();
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const dayToday = new moment().format('Do');
  const [domainName, setDomainName] = useState('');
  const [isValidFormData, setIsValidFormData] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const { id: hostingId, amount, path } = config.service.hosting;

  const onChangeHandler = ({ target: { value } }) => {
    setDomainName(value);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (!elements || !stripe) {
      return;
    }

    setIsValidFormData(true);

    if (!domainName) {
      setIsValidFormData(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, token: { id: stripeTokenId } } = await stripe.createToken(cardElement);

    if (error) {
      setIsValidFormData(false);
      return;
    }

    if (isAuthenticated && stripeTokenId) {
      setPaymentStatus('pending');

      try {
        const auth0Token = await getAccessTokenSilently({
          audience: config.api.url,
          scope: config.api.scope,
        });

        await fetch(config.api.url + path, {
          method: 'POST',
          headers: {
            'Content-Type':'application/json',
            Authorization: `Bearer ${auth0Token}`,
          },
          body: JSON.stringify({
            domainName,
            token: stripeTokenId,
            price: hostingId,
            quantity: 1,
            email: user.email,
          }),
        });
        setPaymentStatus('paid');
      } catch (err) {
        console.log('error thrown', err);
      }
    }
  }

  const renderForm = () => (
    <>
      <div className="row">
        <div className="six columns">
          <fieldset>
            <legend className="visually-hidden">Your website</legend>
            <TextField
              name="domainName"
              id="domainName"
              label="What domain name is this hosting for?"
              onChangeHandler={onChangeHandler}
              hasError={!isValidFormData && !domainName.domainName}
              placeholder="www.your-website-name-here.com"
            />
          </fieldset>
        </div>
        <div className="six columns">
          <p>
            The cost of the domain name registration is not included so if you haven't already
            registered this domain name then you'll need to register it separately.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="six columns">
          <fieldset>
            <legend>Payment</legend>
            <CardElement />
          </fieldset>
          </div>
        <div className="six columns">
          <p>
              We will charge you £{amount} now for the first month and then your card will be
              charged £{amount} on the {dayToday} of each month until you cancel the hosting
              or close your account. You can do either at any time.
            </p>
        </div>
        <button className="button button-primary" type="submit" disabled={!stripe}>Add hosting</button>
      </div>
    </>
  );

  const renderLoading = () => (
    <div className="row">
      <div className="twelve columns">
        <p>Loading...</p>
      </div>
    </div>
  );

  const renderSuccess = () => (
    <div className="row">
      <div className="twelve columns">
        <p>
          Your payment has been successful and your hosting space is being created. We'll email
          you to let you know when it's ready.
        </p>
        <p>
          In the meantime please <Link to="/contact-us">contact us</Link> if you have any
          questions.
        </p>
        <p>Thank you for choosing to host your website with X-Designs.</p>
      </div>
    </div>
  );

  return (
    <Layout pageTitle={pageTitle}>
      <PageTitle pageTitle={pageTitle} />
      <form onSubmit={handleSubmit}>
        <div className="container">
          {!isValidFormData && (
            <div className="row">
              <div className="twelve columns">
                <p className="error-text">
                  There was an error submitting your payment. Please complete all fields to
                  continue.
                </p>
              </div>
            </div>
          )}
          {paymentStatus === null ?
            renderForm()
          :
            paymentStatus === 'pending' ? renderLoading() : renderSuccess()}
        </div>
      </form>
    </Layout>
  );
};

export default AddHosting;
