import React from 'react';

import SupportPage from '../SupportPage/SupportPage';

const EmailAccountSettings = () => (
  <SupportPage pageTitle="Email account settings">
    <h2>Incoming connections</h2>
    <h3>Mail server</h3>
    <ul>
      <li>pdht-f2sx.accessdomain.com</li>
    </ul>
    <h3>Ports</h3>
    <ul>
      <li>IMAP: 993</li>
      <li>POP: 995</li>
    </ul>
    <h2>Outgoing connections</h2>
    <h3>Mail server</h3>
    <ul>
      <li>pdht-f2sx.accessdomain.com</li>
    </ul>
    <h3>Ports</h3>
    <ul>
      <li>465</li>
    </ul>
  </SupportPage>
);

export default EmailAccountSettings;
