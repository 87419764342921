const config = {
  service: {
    hosting: {
      id: 'price_1HjsfxBCgV576dRdDBc1U8XU',
      amount: 15,
      path: '/subscription/hosting',
    }
  },
  api: {
    url: 'https://api.x-designs.co.uk',
    scope: 'add:hosting',
  },
};

export default config;
