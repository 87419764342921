import React from 'react';
import { Link } from 'react-router-dom';

import Teaser from '../Teaser/Teaser';
import Layout from '../Layout/Layout';

import './Overview.css';

const Overview = () => (
  <Layout pageTitle="Overview" mainClass="overview" hasSignUp="true">
    <div className="hero">
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <h1 className="hero__title">Website and email hosting that's reliable, scalable and supported.</h1>
            <p className="hero__text">We'll manage your website and email hosting so you can concentrate on doing what you do best.</p>
            <Link to="/how-does-it-work" className="button button-primary">How does it work?</Link>
          </div>
        </div>
      </div>
    </div>
    <div className="teasers">
      <div className="container">
        <div className="row">
          <div className="four columns">
            <Teaser
              iconClass="simple"
              title="Simple"
              text="Select WordPress, Drupal, Joomla, Magento or one of over 20 managed web apps. Alternatively you can deploy your own custom code."
            />
          </div>
          <div className="four columns">
            <Teaser
              iconClass="helpful"
              title="Helpful"
              text="We'll manage your website hosting which includes software updates and backups and if you have any questions then we're here when you need us."
            />
          </div>
          <div className="four columns">
            <Teaser
              iconClass="fast"
              title="Fast"
              text="Your website will be distributed across hundreds of server processors so it will be able to handle traffic far beyond other hosting platforms."
            />
          </div>
        </div>
        <div className="row">
          <div className="four columns">
            <Teaser
              iconClass="ssl"
              title="SSL"
              text="We can install an existing SSL certificate or a new one for free to help to protect both your data and the data of your customers."
            />
          </div>
          <div className="four columns">
            <Teaser
              iconClass="lock"
              title="Secure"
              text="You can securely upload content to your website and you'll have secure access to your database and email mailboxes."
            />
          </div>
          <div className="four columns">
            <Teaser
              iconClass="flexible"
              title="Flexible"
              text="There is no minimum contract so you'll only pay for the months that you are signed up and you can cancel at any time."
            />
          </div>
        </div>
      </div>
    </div>
    <div className="full-width">
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <Teaser
              iconClass="pointer"
              title="Domain name registration"
              text="If you don't already have a domain name we can register one for you and set it up. We can manage it for you too so you don't need to think about what settings to use or making sure your website is always visible online."
            />
          </div>
        </div>
      </div>
    </div>
    <div className="container overview-contact">
      <div className="row">
        <div className="twelve columns">
          <p>
            If you have any questions you can <Link to="/contact-us">contact us</Link> via twitter,
            email or skype and if you would like help to move your website then let us know...we're
            here to help!
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default Overview;
