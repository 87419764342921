import React from 'react';

import './TextField.css';

const TextField = ({ name, id, label, onChangeHandler, hasError, value, placeholder, disabled }) => (
  <>
    <label htmlFor={id}>{label}</label>
    <input
      type="text"
      name={name}
      id={id}
      onChange={onChangeHandler}
      className={`form-field__field ${hasError ? 'error' : ''}`}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
    />
  </>
);

export default TextField;
