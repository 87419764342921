import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useAuth0 } from '@auth0/auth0-react';

import Nav from '../Nav/Nav';

import './Layout.css';

const Layout = ({ children, mainClass = '', pageTitle, hasSignUp }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const year = new Date().getFullYear();

  return (
    <>
      <Helmet>
        <title>{`${pageTitle} - X-Designs`}</title>
      </Helmet>
      <header>
        <div className="container">
          <div className="row">
            <div className="twelve columns">
              <div className="logo">
                <Link to="/" className="logo__link">X-Designs</Link>
              </div>
              <Nav
                list={[
                  { link: '/', text: 'Overview', className: 'nav__item--mobile' },
                  { link: '/about-us', text: 'About us' },
                  { link: '/how-does-it-work', text: 'How does it work?' },
                  { link: '/features', text: 'Features' },
                  { link: '/contact-us', text: 'Contact us' },
                ]}
                wrapperClass="nav--header"
                listClass="nav__list--header"
                isMainNav={true}
              />
            </div>
          </div>
        </div>
      </header>
      <div className={`main ${mainClass}`}>
        {children}
        {!isAuthenticated && hasSignUp && (
          <div className="full-width">
            <div className="container">
              <div className="row">
                <div className="twelve columns">
                  <p>There is no minimum contract so you'll only pay £15 per month for the months that you are signed up.</p>
                  <Link to="#" className="button button-primary" onClick={() => loginWithRedirect()}>Sign up</Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="twelve columns">
              <Nav list={[
                { link: '/', text: 'Overview' },
                { link: '/about-us', text: 'About us' },
                { link: '/how-does-it-work', text: 'How does it work?' },
                { link: '/features', text: 'Features' },
                { link: '/contact-us', text: 'Contact us' },
              ]} />
              <Nav
                list={[
                  { link: '/system-status', text: 'System status' },
                  { link: '/terms-of-service', text: 'Terms of service' },
                  { link: '/privacy-policy', text: 'Privacy policy' },
                  { link: '/cookies', text: 'Cookies' },
                ]}
                wrapperClass="nav--footer-second"
              />
              <p className="copyright">X-Designs Ltd is registered in England and Wales with company no. 05471189.</p>
              <p>&copy; {year} X-Designs Ltd.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Layout;
