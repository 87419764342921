import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import './Nav.css';

const Nav = ({ list, wrapperClass = '', listClass = '', isMainNav = false }) => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  let [isNavToggledOpen, setIsNavToggledOpen] = useState(false);
  const handleClick = () => setIsNavToggledOpen(!isNavToggledOpen);
  const isMobileView = () => window.innerWidth < 1250;
  const isNavAlwaysOpen = !isMobileView() || !isMainNav;
  const toggleMenu = () => {
    setIsNavToggledOpen(true);

    if (isMobileView()) {
      setIsNavToggledOpen(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', toggleMenu);

    return () => window.removeEventListener('resize', toggleMenu);
  });

  return (
    <div className={`nav ${wrapperClass}`}>
      {isMainNav ? <i className={`nav__mobile-menu-button fa ${isNavToggledOpen ? 'fa-close' : 'fa-navicon'}`} onClick={handleClick} /> : null}
      {(isNavAlwaysOpen || isNavToggledOpen) && (
        <ul className={`nav__list ${listClass}`}>
          {list.map(({ className, link, text, onClick }) => (
            <li key={text} className={`nav__item ${className}`}>
              <NavLink to={link} className="nav__link" onClick={toggleMenu}>{text}</NavLink>
            </li>
          ))}
          {isMainNav ?
            !isAuthenticated ?
              <>
                <li key="sign-in" className="nav__item">
                  <NavLink to="#" className="nav__link" onClick={() => loginWithRedirect()}>Sign in</NavLink>
                </li>
                <li key="sign-up" className="nav__item">
                  <NavLink to="#" className="nav__link" onClick={() => loginWithRedirect()}>Sign up</NavLink>
                </li>
              </>
              :
              <>
                <li key="account" className="nav__item">
                  <NavLink to="/account" className="nav__link" onClick={toggleMenu}>Account</NavLink>
                </li>
                <li key="sign-out" className="nav__item">
                  <NavLink to="#" className="nav__link" onClick={() => logout()}>Sign out</NavLink>
                </li>
              </>
            : null}
        </ul>
      )
      }
    </div >
  );
}

export default Nav;
