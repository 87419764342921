import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Overview from './components/Overview/Overview';
import AboutUs from './components/AboutUs/AboutUs';
import Features from './components/Features/Features';
import SignUp from './components/SignUp/SignUp';
import ContactUs from './components/ContactUs/ContactUs';
import SystemStatus from './components/SystemStatus/SystemStatus';
import TermsOfService from './components/TermsOfService/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Cookies from './components/Cookies/Cookies';
import HowDoesItWork from './components/HowDoesItWork/HowDoesItWork';
import Support from './components/Support/Support';
import LoggingIntoWebmail from './components/LoggingIntoWebmail/LoggingIntoWebmail';
import SettingUpEmailInAppleMail from './components/SettingUpEmailInAppleMail/SettingUpEmailInAppleMail';
import SettingUpEmailInIosMail from './components/SettingUpEmailInIosMail/SettingUpEmailInIosMail';
import SettingUpEmailInWindows10Mail from './components/SettingUpEmailInWindows10Mail/SettingUpEmailInWindows10Mail';
import HowToChangeYourPassword from './components/HowToChangeYourPassword/HowToChangeYourPassword';
import WhatToDoIfYouHaveNotReceivedAnEmail from './components/WhatToDoIfYouHaveNotReceivedAnEmail/WhatToDoIfYouHaveNotReceivedAnEmail';
import WhatIsTheDifferenceBetweenEmailMailboxesAndEmailForwarders from './components/WhatIsTheDifferenceBetweenEmailMailboxesAndEmailForwarders/WhatIsTheDifferenceBetweenEmailMailboxesAndEmailForwarders';
import TransferringAnEmailAccountToSomeoneElse from './components/TransferringAnEmailAccountToSomeoneElse/TransferringAnEmailAccountToSomeoneElse';
import EmailAccountSettings from './components/EmailAccountSettings/EmailAccountSettings';
import NotFound from './components/NotFound/NotFound';
import Account from './components/Account/Account';
import AddHosting from './components/AddHosting/AddHosting';
import AddHostingSuccess from './components/AddHostingSuccess/AddHostingSuccess';

import './styles/normalize.css';
import './styles/skeleton.css';
import './styles/global.css';

const stripePromise = loadStripe('pk_live_mgUQUFshrN1xRhHTLQdU9gWF');

const App = () => (
  <Elements stripe={stripePromise}>
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact component={Overview} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/features" component={Features} />
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/system-status" component={SystemStatus} />
        <Route path="/terms-of-service" component={TermsOfService} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/cookies" component={Cookies} />
        <Route path="/how-does-it-work" component={HowDoesItWork} />
        <Route path="/support" exact component={Support} />
        <Route path="/support/logging-into-webmail" component={LoggingIntoWebmail} />
        <Route path="/support/setting-up-email-in-apple-mail" component={SettingUpEmailInAppleMail} />
        <Route path="/support/setting-up-email-in-ios-mail" component={SettingUpEmailInIosMail} />
        <Route path="/support/setting-up-email-in-windows-10-mail" component={SettingUpEmailInWindows10Mail} />
        <Route path="/support/how-to-change-your-password" component={HowToChangeYourPassword} />
        <Route path="/support/what-to-do-if-you-have-not-received-an-email" component={WhatToDoIfYouHaveNotReceivedAnEmail} />
        <Route path="/support/what-is-the-difference-between-email-mailboxes-and-email-forwarders" component={WhatIsTheDifferenceBetweenEmailMailboxesAndEmailForwarders} />
        <Route path="/support/transferring-an-email-account-to-someone-else" component={TransferringAnEmailAccountToSomeoneElse} />
        <Route path="/support/email-account-settings" component={EmailAccountSettings} />
        <Route path="/account" exact component={Account} />
        <Route path="/account/add-hosting" exact component={AddHosting} />
        <Route path="/account/add-hosting/success" component={AddHostingSuccess} />
        <Route path='*' component={NotFound} />
      </Switch>
    </BrowserRouter>
  </Elements>
)

export default App;
