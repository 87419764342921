import React from 'react';

import './PageTitle.css';

const PageTitle = ({ pageTitle }) => (
  <div className="page-title">
    <div className="container">
      <div className="row">
        <div className="twelve columns">
          <h1 className="page-title__title">{pageTitle}</h1>
        </div>
      </div>
    </div>
  </div>
);

export default PageTitle;