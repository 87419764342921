import React from 'react';
import { Link } from 'react-router-dom';

import Layout from '../Layout/Layout';
import PageTitle from '../PageTitle/PageTitle';

const TermsOfService = () => {
  const pageTitle = 'Terms of service';

  return (
    <Layout pageTitle={pageTitle}>
      <PageTitle pageTitle={pageTitle} />
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <h2>General</h2>
            <ul>
              <li>
                By signing up for a X-Designs account you confirm that you are
                legally capable of entering into a binding contract and you accept and agree to be bound by
                these terms of service or you are acting with the
                express permission and using the payment details of a person or
                organisation who in turn is agreeing to be bound by these terms of service.
              </li>
              <li>
                X-Designs services are not available on a trial basis and it is your
                responsibility to ensure that they meet your technical requirements.
              </li>
            </ul>
            <h2 id="cancellations">Cancellations</h2>
            <ul>
              <li>
                You are entitled to cancel your account by giving us written notification
                no less than one working day before your next payment is due.
              </li>
              <li>
                X-Designs reserves the right to suspend and/or cancel your account if you breech
                these terms of service.
              </li>
            </ul>
            <h2>Refunds</h2>
            <ul>
              <li>
                Charges, which are due monthly in advance, are non-refundable.
              </li>
            </ul>
            <h2>Payment</h2>
            <ul>
              <li>
                You will be charged for your first month when you sign up. Subsequent payments will be
                invoiced automatically on the same day of the month on which you sign up. This date
                will be confirmed on the payment screen during the sign up process.
              </li>
              <li>
                X-Designs reserves the right to change the price or the nature of the service by
                giving you written notification to the email address associated with your account. Any
                change in price or nature of service will become effective on your next
                billing date.
              </li>
              <li>
                All payments are inclusive of applicable taxes.
              </li>
              <li>
                You agree that you are authorised to make payment with the card that you
                use to sign up for an account and in the event that you are not the named
                card holder then you and the card holder acknowledge that you are jointly
                liable for the payment of services.
              </li>
              <li>
                You are responsible for all money owed on your account and you are
                responsible for any costs incurred by X-Designs in the collection of any
                outstanding debt.
              </li>
              <li>
                You are required to have a valid email address and credit/debit card
                associated with your account at all times. If you cancel your credit/debit
                card then you are required to notify us immediately.
              </li>
              <li>
                All invoices will be sent to the email address associated with your account immediately
                after payment transactions have been completed.
              </li>
              <li>
                If you fail to make payment then X-Designs reserves the right to suspend or cancel
                your services until payment has resumed and all outstanding payments have
                been paid.
              </li>
            </ul>
            <h2 id="use-of-services">Use of services</h2>
            <ul>
              <li>
                X-Designs reserves the right to suspend or cancel a customer's access to any or all
                services provided by X-Designs, where it decides that the account has
                been inappropriately used.
              </li>
              <li>
                X-Designs reserves the right to refuse services and/or access to it's servers to
                anyone.
              </li>
              <li>
                X-Designs offers web space and bandwidth. By this, we mean web
                space for legitimate web site content and bandwidth for visitors to view
                it. All files on a domain must be part of the active website and linked to
                the site. Sites should not contain any material which does not comply with
                our <Link to="#content-guidelines">content guidelines</Link>.
              </li>
              <li>
                X-Designs offers 10 mailboxes per account. Mailboxes are
                intended for direct use by the package owner and should not be resold or
                offered for use by third parties.
              </li>
              <li>
                By using X-Designs you agree not to re-sell or offer for the use of
                third parties any part of it's services.
              </li>
            </ul>
            <h2 id="acceptable-use-guidelines">Acceptable use guidelines</h2>
            You may not use X-Designs's services:
            <ul>
              <li>
                in any way that breaches any applicable regulation, UK or
                International law;
              </li>
              <li>
                in any way that is fraudulent;
              </li>
              <li>
                to host any material which is in breach of our {" "}
                <Link to="#content-guidelines">content guidelines</Link>;
              </li>
              <li>
                to transmit, or otherwise permit any unsolicited or unauthorised
                advertising or promotional material or any other form of similar
                marketing material, also known as SPAM or Unsolicited Commercial
                Email;
              </li>
              <li>
                to publish or otherwise distribute Warez, copywritten or other
                illegal material. The onus is on you the customer to prove that you
                own the rights to publish material, not for X-Designs to prove that you do
                not;
              </li>
              <li>
                to participate in any file-sharing or peer-to-peer activities;
              </li>
              <li>
                as a file backup store. All files uploaded to a domain on X-Designs's
                servers must be visible and accessible by visiting that domain,
                unless they are hidden files needed to operate the website; X-Designs
                reserves the right to delete files which do not match these criteria
                without giving notice to you;
              </li>
              <li>
                to use the server as an application server. Using the server to
                generate large volumes of email from a database is an example of
                activity that is not allowed. Scripts should not attempt to
                manipulate the timeouts on servers. These are set at preset
                values to ensure the reliability of the server. Sites that reset
                these do so because they are resource intensive, and adversely
                affect server performance and are therefore not allowed. Scripts
                that are designed to provide proxy services, anonymous or
                otherwise, are not allowed;
              </li>
              <li>
                to use an excessive amount of server resources. These include
                bandwidth, processor utilization and/or disk space;
              </li>
              <li>
                to offer HTTP downloads from your site, other than where the files
                are directly related to the subject matter of the site. X-Designs reserves
                the right to archive any download content it does not believe to be
                necessary for the normal operation of the site, and the definition
                of this is entirely at the discretion of X-Designs;
              </li>
              <li>
                to redirect users from a non-.XXX website to a .XXX website.
              </li>
            </ul>
            <h2 id="content-guidelines">Content guidelines</h2>
            <ul>
              <li>
                Material must not
                <ul>
                  <li>
                    be defamatory of any person;
                  </li>
                  <li>
                    be obscene, offensive, or inflammatory;
                  </li>
                  <li>
                    contain any adult material with the exception of content hosted on
                    .XXX domains. Adult material includes all pornography; or otherwise
                    lewd or obscene content. The designation of 'adult material' is
                    left entirely to the discretion of X-Designs;
                  </li>
                  <li>
                    material hosted on .XXX domains must not breach any of the IFFOR
                    policies. These include, but are not limited to:
                    <ul>
                      <li>
                        .XXX registrants must not display any photograph, film,
                        video, picture, or computer or computer-generated image or
                        picture, whether made or produced by electronic,
                        mechanical, or other means, depicting child pornography as
                        defined in the United Nations Convention on the Rights of
                        the child;
                      </li>
                      <li>
                        .XXX registrants must agree to permit automated monitoring
                        of their sites by IFFOR or if applicable, any other
                        recognised third party for compliance with IFFOR policies,
                        including without limitation, IFFOR policies requiring site
                        labelling, prohibiting child pornography, and prohibiting
                        content or conduct designed to suggest the presence of
                        child pornography. Registrants must agree not to employ
                        technological or other means to defeat or prevent such
                        monitoring;
                      </li>
                      <li>
                        No registrant shall use or permit use of a .XXX domain name
                        for or in connection with email spoofing, phishing, spam,
                        or other forms of malicious behaviour, in accordance with
                        specifications and policies issued by IFFOR from time to
                        time;
                      </li>
                      <li>
                        No registrant may register an abusive string in the sTLD
                        including, without limitation, strings that infringe the
                        intellectual property rights of a third party, including
                        common law trademark rights; strings that are obvious
                        variants of well-known trademarks not belonging to the
                        registrant; first and last names of an individual other
                        than the individual or his/her agent or names that suggest
                        the presence of child pornography.
                      </li>
                    </ul>
                  </li>
                  <li>
                    infringe any copyright, or trade mark;
                  </li>
                  <li>
                    be likely to deceive any person;
                  </li>
                  <li>
                    promote or assist any illegal activity;
                  </li>
                  <li>
                    be threatening, abusive or invade another's privacy, or cause
                    annoyance, inconvenience or needless anxiety;
                  </li>
                  <li>
                    be used to to misrepresent your identity or affiliation with any
                    person or organization.
                  </li>
                </ul>
              </li>
              <li>
                You must ensure that any end-user submitted content such as forum posts or
                chat room entries meet with the standards defined within these terms;
              </li>
              <li>
                X-Designs does not allow any content which breaches it's <Link to="#acceptable-use-guidelines">acceptable use guidelines</Link> to be
                stored on its servers. X-Designs reserves the right to remove content from the
                services or suspend the services immediately where it reasonably suspects
                such content breaches the <Link to="#acceptable-use-guidelines">acceptable use guidelines</Link>;
              </li>
              <li>
                X-Designs shall notify you if it becomes aware of any allegation that you breach
                the <Link to="#acceptable-use-guidelines">acceptable use guidelines</Link>;
              </li>
              <li>
                Refusal of service based on the content being contrary to the {" "}
                <Link to="#acceptable-use-guidelines">acceptable use guidelines</Link> is entirely
                at the discretion of X-Designs;
              </li>
              <li>
                X-Designs reserves the right to move your data to a different server
                with no prior notice;
              </li>
              <li>
                You shall indemnify X-Designs against all damages, losses and
                expenses arising as a result of any action or claim that the data, content
                and/or any other material breaches the {" "}
                <Link to="#acceptable-use-guidelines">acceptable use guidelines</Link>;
              </li>
              <li>
                In the event that X-Designs removes data and/or content from it's services
                and/or suspends your site as detailed in the <Link to="#use-of-services">use of services</Link>, and later reinstates such
                data and/or content and/or resumes the services, you shall indemnify X-Designs
                against all damages, losses and expenses arising as a result of any action
                or claim that such content and/or data and/or the services your site
                breaches the <Link to="#acceptable-use-guidelines">acceptable use guidelines</Link>.
              </li>
            </ul>
            <h2>Scheduled maintenance</h2>
            <ul>
              <li>
                To guarantee optimal server performance, it is necessary for
                X-Designs to perform routine maintenance. Such maintenance may
                require taking X-Designs services offline, typically performed
                during off-peak hours. X-Designs will give you advance notice of
                maintenance requiring the services to be taken offline whenever possible by
                publishing notice on the <Link to="/system-status">system status</Link> page.
              </li>
            </ul>
            <h2>Data</h2>
            <ul>
              <li>
                All data created or stored by you within X-Designs's servers are your
                property. X-Designs shall allow access to such data by only
                authorised X-Designs personnel. X-Designs makes no claim of
                ownership of any web server content, email content, or any other type of
                data contained within the account holder's server space or within
                applications on X-Designs's servers.
              </li>
              <li>
                X-Designs maintains multiple backups of websites and databases stored on it's
                servers for up to 30 days. In the event of loss of or damage to your data
                relating to actions made by you or on your behalf we will restore your website
                or database up to a maximum of twice per month. Further data restoration will
                be subject to our data restoration fee. Restoring data will overwrite all
                previous data and is not reverseable.
              </li>
              <li>
                In the event of loss of or damage to your data relating to a failure in
                X-Designs systems or servers, X-Designs will make reasonable
                commercial efforts to assist you in the restoration of your data, however
                you accept full responsibility for maintaining adequate backup copies of
                all your data.
              </li>
              <li>
                You shall indemnify X-Designs against all damages, losses and
                expenses arising as a result of any action or claim that the content or
                data of your site or content or data accessed from or published as part of
                the services infringes the intellectual property rights of a third party.
              </li>
            </ul>
            <h2>Uploads via scripting languages</h2>
            <ul>
              <li>
                X-Designs limits uploads made via scripting languages including PHP.
              </li>
            </ul>
            <h2>Passwords</h2>
            <ul>
              <li>
                It is the account owner's responsibility to keep his/her password(s)
                confidential and to change his/her password(s) on a regular basis.
                X-Designs is not responsible for any data losses or security issues
                due to stolen passwords or any passwords that you have intentionally or
                accidentally disclosed to any third party. X-Designs recommends that
                you use passwords that contain a mix of lowercase, uppercase, numbers and symbols in order to prevent
                unauthorized users from guessing commonly used choices, such as"12345",
                "password", etc.
              </li>
              <li>
                You accept full responsibility for any purchases or modifications made
                within your account by you or by third parties using your account
                password.
              </li>
            </ul>
            <h2>Your personal details</h2>
            <ul>
              <li>
                You warrant that the contact information that you provide to us when signing up is correct and that you accept
                responsibility for keeping this information up to date at all times. You
                agree that X-Designs may suspend access to your account and the services if it
                reasonably believes that the information you have supplied is inaccurate.
              </li>
            </ul>
            <h2>X-Designs Disclaimers and Warranties</h2>
            <ul>
              <li>
                X-Designs does not back up your data/website and whilst every attempt
                would be made in the unlikely event of any corruption or hardware failure,
                X-Designs cannot guarantee to be able to replace lost data. This
                includes loss of data resulting from delays, non-deliveries, wrong
                delivery, and any and all service interruptions caused by X-Designs.
              </li>
              <li>
                X-Designs makes no warranties or representations that any service
                will be uninterrupted or error free. You accept all services provided
                hereunder "as is" without warranty of any kind.
              </li>
              <li>
                So far as permitted by law and particularly in respect of non-consumers,
                all implied conditions, warranties and terms (whether express or implied by
                statute, common law, custom or otherwise) including, but not limited to,
                those relating to the exercise of reasonable care and skill, fitness for
                purpose and satisfactory quality (where applicable) are hereby excluded in
                relation to each of the services to be provided hereunder to the fullest
                extent permitted by law.
              </li>
              <li>
                For the avoidance of doubt, any use of the services in the course of
                conducting business shall give rise to you being a non-consumer and the
                provisions of these terms affecting the statutory consumer protection
                you would otherwise be afforded as a consumer shall not apply.
              </li>
            </ul>
            <h2>Liability</h2>
            <ul>
              <li>
                X-Designs shall not be liable for any loss or damage of whatsoever
                nature suffered by you arising out of or in connection with any breach of
                these terms by you or any act, misrepresentation, error or omission made
                by you or on your behalf.
              </li>
              <li>
                X-Designs will not be liable for any indirect loss, consequential
                loss, loss of profit, revenue, data or goodwill howsoever arising suffered
                by you or for any wasted management time or failure to make anticipated
                savings or liability you incur to any third party arising in any way in
                connection with these terms or otherwise whether or not such loss has
                been discussed by the parties pre-contract or for any account for profit,
                costs or expenses arising from such damage or loss.
              </li>
              <li>
                No matter how many claims are made and whatever the basis of such claims,
                X-Designs's maximum aggregate liability to you under or in connection
                with these terms in respect of any direct loss (or any other loss to the
                extent that such loss is not excluded by other provisions in these terms)
                whether such claim arises in contract or in tort shall not
                exceed a sum equal to the fees paid by you for the services in relation to
                which your claim arises during the 12 month period prior to such claim.
              </li>
              <li>
                None of the clauses herein shall apply so as to restrict liability for
                death or personal injury resulting from the negligence of X-Designs,
                its employees or its sub-contractors.
              </li>
              <li>
                X-Designs shall not be liable for any interruptions to the services
                or outages arising directly or indirectly from:-
                <ul>
                  <li>
                    interruptions to the flow of data to or from the internet;
                  </li>
                  <li>
                    changes, updates or repairs to the network or software which it
                    uses as a platform to provide the services;
                  </li>
                  <li>
                    the effects of the failure or interruption of services provided by
                    third parties;
                  </li>
                  <li>
                      factors outside of X-Designs's reasonable control;
                  </li>
                  <li>
                    your actions or omissions (including, without limitation, breach of
                    your obligations set out in these terms) or those of any third
                    parties;
                  </li>
                  <li>
                    problems with your equipment and/or third party equipment;
                  </li>
                  <li>
                    interruptions to the services requested by you.
                  </li>
                </ul>
              </li>
            </ul>
            <h2>Force majeure</h2>
            <ul>
              <li>
                X-Designs shall not be responsible for any failure to provide any
                services or perform any obligation under the agreement because of any act
                of God, strike, lock-outs or other industrial disputes whether involving
                the workforce of X-Designs (or any other party) or compliance with
                any law of governmental or any other order, rule, regulation or direction,
                accident, fire, flood, storm, default of suppliers, work stoppage, war,
                riot or civil commotion, equipment or facilities shortages which are being
                experienced by providers of telecommunication services generally, or other
                similar force beyond its reasonable control.
              </li>
            </ul>
            <h2>Non-Waiver</h2>
            <ul>
              <li>
                The failure of X-Designs to require your performance of any provision
                shall not affect the full right to require such performance at any time
                thereafter; nor shall the waiver by X-Designs of a breach of any
                provision hereof be taken or held to be a waiver of the provision itself.
              </li>
            </ul>
            <h2>Survival</h2>
            <ul>
              <li>
                The provisions, terms, conditions representations, warranties, covenants,
                and obligations contained in or imposed by these terms which by their
                performance after their termination, shall be and remain
                enforceable notwithstanding their termination for any reason.
                However, neither party shall be liable to other for damages of any sort
                resulting solely from terminating these terms in accordance with their
                terms but each party shall be liable for any damage from any breach by it
                of these terms.
              </li>
            </ul>
            <h2>Notice</h2>
            <ul>
              <li>
                You agree that any notice or communications required or permitted to be
                delivered under these terms by X-Designs to you shall be deemed to
                have been given if delivered by email, in accordance with the contact
                information you have provided.
              </li>
            </ul>
            <h2>Governing law</h2>
            <ul>
              <li>
                Your rights and obligations and all contemplated by these terms shall be
                governed by English law and you submit to the exclusive jurisdiction of the
                English Courts.
              </li>
            </ul>
            <h2>Legal fees</h2>
            <ul>
              <li>
                If any legal action or proceeding, including arbitration, relating to the
                performance or the enforcement of any provision of these terms is
                brought by any party to these terms, the prevailing party shall be
                entitled to recover reasonable legal fees, expert witness fees, costs and
                disbursements, in addition to any other relief to which the prevailing
                party may be entitled.
              </li>
            </ul>
            <h2>Assignment</h2>
            <ul>
              <li>
                You shall not assign, sub-license or transfer your rights or obligations
                under these terms to any third party without the prior written consent
                of X-Designs. However, in the event that X-Designs consents to
                such an assignment, sub-license or transfer, then these terms shall
                ensure to the benefit of and be binding upon the parties and their
                respective successors and permitted assigns.
              </li>
            </ul>
            <h2>Entire agreement</h2>
            <ul>
              <li>
                These terms constitute the entire agreement between the parties and
                agreements are representations or warranties, express or implied, statutory
                or otherwise and no agreements collateral here to than as expressly set or
                referred to herein. These terms supersede any prior agreements,
                representations, statements, negotiations, understandings, proposals or
                undertakings, oral or written, with respect to the subject matter expressly
                set forth herein.
              </li>
            </ul>
            <h2>Amendment in writing</h2>
            <ul>
              <li>
                We may update or amend these terms of service including any technical
                specification relating to the services, the <Link to="/privacy-policy">privacy policy</Link> and any
                information relating to the services from time to time to comply with law
                or to meet our changing business requirements. We will give you prior
                notice of any changes to these terms and you can choose to cancel the
                services without penalty before the new terms affect you. Display of the
                modified terms of service shall be deemed to be notice to you. You also
                agree to review the terms of service regularly to ensure you are aware of
                any modifications.
              </li>
            </ul>
            <h2>Further assurances</h2>
            <ul>
              <li>
                The parties shall execute such further and other documents and instruments
                and take such further and other actions as may be necessary to carry out
                and give full effect to the transactions contemplated by these terms of service.
              </li>
            </ul>
            <h2>Relationship of the parties</h2>
            <ul>
              <li>
                Nothing in these terms of service shall be construed as creating an agency
                relationship, partnership or joint venture between the parties.
              </li>
            </ul>
            <h2>Joint and several obligations</h2>
            <ul>
              <li>
                If any party consists of more than one entity, their obligations here under
                are joint and several.
              </li>
            </ul>
            <h2>No third party beneficiaries</h2>
            <ul>
              <li>
                These terms of service do not provide and shall not be constructed to provide any
                third parties, with any remedy, claim, cause of action or privilege.
              </li>
            </ul>
            <h2>Severability</h2>
            <ul>
              <li>
                In the event that any provision of these terms of service shall be unenforceable or
                invalid under any applicable law or be so held by applicable court
                decision, such unenforceability or invalidity shall not render these
                terms of service unenforceable or invalid as a whole. X-Designs will amend
                or replace such provision with one that is valid and enforceable and which
                achieves, to the extent possible, the original objectives and intent of
                X-Designs as reflected in the original provision.
              </li>
            </ul>
            <h2>Enforcement</h2>
            <ul>
              <li>
                We will determine, in our discretion, whether there has been a breach of
                the <Link to="#acceptable-use-guidelines">acceptable use guidelines</Link> through your use of X-Designs services.
                When a breach of these terms has occurred, we may take such action as we
                deem appropriate in accordance with the following section.
              </li>
              <li>
                Failure to comply with these terms of service upon which you are permitted
                to use X-Designs services may result in our taking all or any of
                the following actions:
                <ul>
                  <li>
                    immediate, temporary or permanent withdrawal of your right to use
                    X-Designs services;
                  </li>
                  <li>
                    immediate, temporary or permanent removal or archiving of any
                    content in breach of our {" "}
                    <Link to="#content-guidelines">content guidelines</Link> uploaded to our servers;
                  </li>
                  <li>
                    issue of a warning to you;
                  </li>
                  <li>
                    issue of legal proceedings against you for reimbursement of all
                    costs on an indemnity basis (including, but not limited to, reasonable
                    administrative and legal costs) resulting from the breach;
                  </li>
                  <li>
                    further legal action against you; or
                  </li>
                  <li>
                    disclosure of such information to law enforcement authorities as we
                    reasonably feel is necessary.
                  </li>
                </ul>
              </li>
              <li>
                We exclude liability for actions taken in response to breaches of these
                terms of service. The responses described in these terms are not
                limited, and we may take any other action we reasonably deem appropriate.
              </li>
            </ul>
            <p>
              If you have any questions about these terms of service then please {" "}
              <Link to="/contact-us">contact us</Link>.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsOfService;
