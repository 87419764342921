import React from 'react';

import Layout from '../Layout/Layout';
import SystemStatusItem from '../SystemStatusItem/SystemStatusItem';
import PageTitle from '../PageTitle/PageTitle';

const SystemStatus = () => {
  const pageTitle = 'System status';

  return (
    <Layout pageTitle={pageTitle}>
      <PageTitle pageTitle={pageTitle} />
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <h2>Overview</h2>
            <p>This is a view of the current performance of our services and you can check back if you experience a service disruption.</p>
          </div>
        </div>
        <div className="row centered">
          <div className="one-third column">
            <SystemStatusItem
              title="Website"
              text="Website status good"
            />
          </div>
          <div className="one-third column">
            <SystemStatusItem
              title="Database"
              text="Database status good"
            />
          </div>
          <div className="one-third column">
            <SystemStatusItem
              title="Email"
              text="Email status good"
            />
          </div>
        </div>
        <div className="row">
          <div className="twelve columns">
            <h2>Scheduled maintenance</h2>
            <p>There is currently no scheduled maintenance planned.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SystemStatus;
