import React from 'react';

import Layout from '../Layout/Layout';
import PageTitle from '../PageTitle/PageTitle';

const Cookies = () => {
  const pageTitle = 'Cookies';

  return (
    <Layout pageTitle={pageTitle}>
      <PageTitle pageTitle={pageTitle} />
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <p>
              A cookie is a small file which is placed on your computer's hard drive and allows
              websites to respond to you as an individual. The website can tailor its operations
              to your needs, likes and dislikes by gathering and remembering information about
              your preferences.
            </p>
            <p>
              We use traffic log cookies to identify which pages are being used. This helps
              us analyse data about webpage traffic and improve our website in order to tailor
              it to our customer's needs. We only use this information for statistical analysis
              purposes.
            </p>
            <p>
              Overall, cookies help us provide you with a better website by enabling us to
              monitor which pages you find useful and which you do not. A cookie in no way gives
              us access to your computer or any information about you, other than the data you
              choose to share with us.
            </p>
            <p>
              You can choose to accept or decline cookies. Most web browsers automatically accept
              cookies but you can usually modify your browser setting to decline cookies if you
              prefer. This may prevent you from taking full advantage of the website.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Cookies;
