import React from 'react';
import { Link } from 'react-router-dom';

import SupportPage from '../SupportPage/SupportPage';

const TransferringAnEmailAccountToSomeoneElse = () => (
  <SupportPage pageTitle="Transferring an email account to someone else">
    <p>
      Transferring an email account to someone else is straightforward but the process is
      different for email mailboxes and email forwarders. If you're not sure of the difference between email mailboxes and email forwarders
      then please please read our guide on {' '}
      <Link to="/support/what-is-the-difference-between-email-mailboxes-and-email-forwarders"> the difference between email mailboxes and email forwarders</Link> {' '}
      first.
    </p>
    <h2>Email mailboxes</h2>
    <p>
      Email mailboxes are not assigned to a specific person so they can be easily transferred
      from one person to another without a support request.
    </p>
    <p>To transfer an email mailbox:</p>
    <ol>
      <li>The existing owner should remove any emails from the account that they don't wish to transfer with the account</li>
      <li>The existing owner should remove the email account from any desktop/laptop/mobile devices on which it has been set up</li>
      <li>The existing owner should pass the email address and the current password for the account to the new owner</li>
      <li>The new owner can <Link to="/support/how-to-change-your-password">change the password</Link> of the email mailbox if they wish</li>
      <li>The new owner can use the email account by <Link to="/support/logging-into-webmail">logging into webmail</Link></li>
      <li>The new owner can set up the email account on their desktop/laptop/mobile device if they wish</li>
    </ol>
    <h2>Email forwarders</h2>
    <p>
      Email forwarders are assigned to a specific person or group of people so they require a support request to
      be transferred.
    </p>
    <p>
      To transfer an email forwarder <Link to="/contact-us">contact us</Link> to create a
      support request letting us know the new email address to which emails should be forwarded.
    </p>
  </SupportPage>
);

export default TransferringAnEmailAccountToSomeoneElse;
