import React from 'react';

import SupportPage from '../SupportPage/SupportPage';

const SettingUpEmailInWindows10Mail = () => (
  <SupportPage pageTitle="Setting up email in Windows 10 Mail Mail">
    <ol>
      <li>Open the Mail app</li>
      <li>Click Add account</li>
      <li>Click Advanced Setup</li>
      <li>Click Internet email</li>
      <li>Click Mail account</li>
      <li>Enter the following:
        <ul>
          <li>Email Address: Your email address</li>
          <li>Username: Your email address</li>
          <li>Password: Your email password</li>
          <li>Account name: This is for you to easily identify the account in the app</li>
        </ul>
      </li>
      <li>Enter the following:
        <ul>
          <li>Send messages using this name: This will be the name that appears in the From field of your email</li>
          <li>Incoming email server: pdht-f2sx.accessdomain.com:993</li>
          <li>Account Type: IMAP4</li>
          <li>Outgoing (SMTP) email server: pdht-f2sx.accessdomain.com:465</li>
        </ul>
      </li>
      <li>Select the following:
        <ul>
          <li>Outgoing server requires authentication: Selected</li>
          <li>Use the same username and password for sending email: Selected</li>
          <li>Require SSL for incoming mail: Selected</li>
          <li>Require SSL for outgoing mail: Selected</li>
        </ul>
      </li>
      <li>Click Sign In</li>
    </ol>
  </SupportPage>
);

export default SettingUpEmailInWindows10Mail;
