import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Layout from '../Layout/Layout';
import TextField from '../TextField/TextField';
import PageTitle from '../PageTitle/PageTitle';
import ServiceList from '../ServiceList/ServiceList';

const Account = () => {
  const pageTitle = 'Account';
  const { isAuthenticated, isLoading, user } = useAuth0();

  return (
    <Layout pageTitle={pageTitle} hasSignUp="true">
      <PageTitle pageTitle={pageTitle} />
      <div className="container">
      <div className="row">
          {isLoading ?
          <div className="six columns">
            <p>Loading...</p>
          </div>
          :
          isAuthenticated && (
            <>
              <div className="six columns">
                <h2>Your services</h2>
                <ServiceList
                  title="Hosting"
                  list={[]}
                  link={{ link: '/account/add-hosting', text: 'Add hosting' }}
                />
              </div>
              <div className="six columns">
                <h2>Your details</h2>
                <TextField
                  name="email"
                  id="email"
                  label="Email"
                  value={user.email}
                  disabled={true}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Account;
