import React from 'react';
import { Link } from 'react-router-dom';

const SupportList = ({ title, list }) => (
  <>
    <h3>{title}</h3>
    <ul>
      {list.map(({ link, text }) => (
        <li key={text}><Link to={link}>{text}</Link></li>
      ))}
    </ul>
  </>
);

export default SupportList;
