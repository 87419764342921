import React from 'react';
import { Link } from 'react-router-dom';

import Layout from '../Layout/Layout';
import SupportList from '../SupportList/SupportList';
import PageTitle from '../PageTitle/PageTitle';

const Support = () => {
  const pageTitle = 'Support';

  return (
    <Layout pageTitle={pageTitle}>
      <PageTitle pageTitle={pageTitle} />
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <h2>Email</h2>
            <SupportList
              title="Accessing email"
              list={[
                {link: '/support/logging-into-webmail', text: 'Logging into webmail'},
                {link: '/support/setting-up-email-in-apple-mail', text: 'Setting up email in Apple Mail'},
                {link: '/support/setting-up-email-in-ios-mail', text: 'Setting up email in iOS Mail'},
                {link: '/support/setting-up-email-in-windows-10-mail', text: 'Setting up email in Windows 10 Mail'},
                {link: '/support/email-account-settings', text: 'Email account settings'},
              ]}
            />
            <SupportList
              title="Managing email"
              list={[
                {link: '/support/how-to-change-your-password', text: 'How to change your password'},
                {link: '/support/what-to-do-if-you-have-not-received-an-email', text: 'What to do if you have not received an email'},
                {link: '/support/what-is-the-difference-between-email-mailboxes-and-email-forwarders', text: 'What is the difference between email mailboxes and email forwarders'},
                {link: '/support/transferring-an-email-account-to-someone-else', text: 'Transferring an email account to someone else'},
              ]}
            />
            <p>If you can't find the answer you're looking for then you can <Link to="/contact-us">contact us</Link> for further help.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Support;
