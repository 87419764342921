import React from 'react';

import './FeatureList.css';

const FeatureList = ({ list, listClass }) => (
  <div className="features">
    <ul className={`features__list ${listClass}`}>
      {list.map(item => (
        <li key={item} className="features__item">{item}</li>
      ))}
    </ul>
  </div>
);


export default FeatureList;
