import React from 'react';
import { Link } from 'react-router-dom';

import Layout from '../Layout/Layout';
import PageTitle from '../PageTitle/PageTitle';

const SignUpSuccess = () => {
  const pageTitle = 'Add hosting';

  return (
    <Layout pageTitle={pageTitle}>
      <PageTitle pageTitle={pageTitle} />
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <p>
              Your payment has been successful and your hosting space is being created. We'll email
              you to let you know when it's ready.
            </p>
            <p>
              In the meantime please <Link to="/contact-us">contact us</Link> if you have any
              questions.
            </p>
            <p>Thank you for choosing to host your website with X-Designs.</p>
          </div>
        </div>
      </div>
    </Layout>
  )
};

export default SignUpSuccess;
