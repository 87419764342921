import React from 'react';

import './Teaser.css';

const Teaser = ({ iconClass, title, text }) => (
  <div className="teaser">
    <div className={`teaser__icon teaser__icon--${iconClass}`}></div>
    <h2 className="teaser__title">{title}</h2>
    <p className="teaser__text">{text}</p>
  </div>
);

export default Teaser;
