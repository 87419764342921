import React from 'react';

import SupportPage from '../SupportPage/SupportPage';

const WhatToDoIfYouHaveNotReceivedAnEmail = () => (
  <SupportPage pageTitle="What to do if you have not received an email">
    <h2>Check in webmail</h2>
    <ol>
      <li>Your email may have been flagged as spam and sent to the Spam folder.</li>
      <li>If you have changed the spam settings from the default to delete the spam and the email was flagged as spam then it would have been deleted.</li>
    </ol>
    <h2>Check on other devices</h2>
    <ol>
      <li>Your email may have been flagged as spam and sent to the Spam folder. Also check you Spam folder in webmail because if your Spam folder is not synced properly then it would not appear on your device.</li>
      <li>If your email account is set up with an account type of POP rather than IMAP then it may have been downloaded to that device and therefore not displayed in webmail.</li>
      <li>If you have a filter set up the email may have been sent to a folder other than the inbox.</li>
    </ol>
  </SupportPage>
);

export default WhatToDoIfYouHaveNotReceivedAnEmail;
