import React from 'react';

import SupportPage from '../SupportPage/SupportPage';

const LoggingIntoWebmail = () => (
  <SupportPage pageTitle="Logging into webmail">
    <ol>
      <li>Open your web browser and enter the address for your website followed by /webmail
        <ul>
          <li>For example: https://www.your-website-name-here.com/webmail</li>
        </ul>
      </li>
      <li>Enter the following:
        <ul>
          <li>Email Address: Your email address</li>
          <li>Password: Your email password</li>
        </ul>
      </li>
    </ol>
  </SupportPage>
);

export default LoggingIntoWebmail;
