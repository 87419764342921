import React from 'react';
import { Link } from 'react-router-dom';

import Layout from '../Layout/Layout';
import PageTitle from '../PageTitle/PageTitle';

const NotFound = () => {
  const pageTitle = 'Page not found';

  return (
    <Layout pageTitle={pageTitle}>
      <PageTitle pageTitle={pageTitle} />
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <p>Unfortunately the page you're looking for could not be found.</p>
            <p>
              We suggest that you try one of the links above or {' '}
              <Link to="/contact-us">contact us</Link> for more information.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
