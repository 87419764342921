import React from 'react';
import { Link } from 'react-router-dom';

import Layout from '../Layout/Layout';
import PageTitle from '../PageTitle/PageTitle';

const HowDoesItWork = () => {
  const pageTitle = 'How does it work?';

  return (
    <Layout pageTitle={pageTitle} hasSignUp="true">
      <PageTitle pageTitle={pageTitle} />
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <p>
              Your website will generally consist of a series of static files or a series of dynamic files
              and a database.
            </p>
            <p>
              When you sign up for an account you’ll get access to the things you need for your website to
              work such as website space and a database and also some email mailboxes. You can find out what
              else is included with your account on the <Link to="/features">features</Link> page.
            </p>
            <p>Once you've created your account there are 3 steps to setting up your website.</p>
            <h2>1. Move or create your website</h2>
            <p>
              If you know how to set your website up in your new account then you'll have the settings you need
              to do this or, if you prefer, we can help you move your website or if you need a new website then
              we can help you get the foundation set up so that you or your website designer/developer can easily
              set up the website.
            </p>
            <h2>2. Configure your domain name</h2>
            <p>
              Your domain name is something like www.your-website-name-here.com and it will need to be directed
              to your new account. If you know how to configure your domain name then you'll have the settings you
              need to do this or, if you prefer, we can configure it for you.
            </p>
            <h2>3. Set up your email mailboxes</h2>
            <p>
              You’ll get some email mailboxes to use with your domain name so they are consistently branded with
              your website. You’ll just need to choose the names of the email addresses, which will be something
              like your.name@your-website-name-here.com, and we can set them up for you.
            </p>
            <h2>Have any questions?</h2>
            <p>
              We're always here so if you have any questions then please <Link to="/contact-us">contact us</Link>.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
};

export default HowDoesItWork;
