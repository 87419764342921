import React from 'react';

import SupportPage from '../SupportPage/SupportPage';

const SettingUpEmailInIosMail = () => (
  <SupportPage pageTitle="Setting up email in iOS mail">
    <ol>
      <li>Open Settings</li>
      <li>Tap Passwords &amp; Accounts</li>
      <li>Tap Add Account</li>
      <li>Tap Other</li>
      <li>Tap Add Mail Account</li>
      <li>Enter the following:
        <ul>
          <li>Name: This will be the name that appears in the From field of your email</li>
          <li>Email: Your email address</li>
          <li>Password: Your email password</li>
          <li>Description: This is to help you identify your account in the mail app</li>
        </ul>
      </li>
      <li>Tap Next</li>
      <li>Enter the following for Incoming Mail Server:
        <ul>
          <li>Host Name: pdht-f2sx.accessdomain.com</li>
          <li>Username: Your email address</li>
          <li>Password: Your email password</li>
        </ul>
      </li>
      <li>Enter the following for Outgoing Mail Server:
        <ul>
          <li>Host Name: pdht-f2sx.accessdomain.com</li>
          <li>Username: Your email address</li>
          <li>Password: Your email password</li>
        </ul>
      </li>
      <li>Tap Next</li>
      <li>Tap Save</li>
    </ol>
  </SupportPage>
);

export default SettingUpEmailInIosMail;
