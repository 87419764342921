import React from 'react';
import { Link } from 'react-router-dom';

import Layout from '../Layout/Layout';
import PageTitle from '../PageTitle/PageTitle';

const SignUp = () => {
  const pageTitle = 'Sign up';

  return (
    <Layout pageTitle={pageTitle}>
      <PageTitle pageTitle={pageTitle} />
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <p>
              We have rebuilt our website using more modern frontend technologies and are in the
              process of rebuilding our sign up process. We're doing this because the new
              technologies will make it much easier for us to update our website, which we like
              to do regularly to add things like new features and support guides.
            </p>
            <p>
              This means our sign up process will be unavailable for a little while, however if
              you would like to sign up for a hosting account please {' '}
              <Link to="/contact-us">contact us</Link> and we will sign you up with a new
              account.
            </p>
            <p>
              We're sorry for any inconvenience this temporarily causes our new customers. We
              considered waiting until we'd rebuilt the sign up process before we launched our
              new website but decided that there were a few new features we wanted to add for the
              benefit of our existing customers and these were much easier and quicker to build
              and launch with our new website than with our old website.
            </p>
            <p>You can <Link to="/contact-us">contact us</Link> if you have any questions.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SignUp;
