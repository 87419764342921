import React from 'react';
import { Link } from 'react-router-dom';

import './ServiceList.css';

const ServiceList = ({ title, list, link }) => (
  <div className="service-list">
    <h3>{title}</h3>
    {list.length > 0 &&
      <ul className="service-list__list">
        {list.map(({ link, text }) => (
          <li key={text}>{text}</li>
        ))}
      </ul>
    }
    <div><Link to={link.link}>{link.text}</Link></div>
  </div>
);

export default ServiceList;
